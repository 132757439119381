import React,{Component} from 'react'
import Slider from "react-slick";
import moment from 'moment';
import 'moment/locale/ro';
import {connect} from 'react-redux'
import {openModal} from "../../../store/actions/modalActions";
import HoursColumn from '../tables/HoursColumn'
import {subDays, addDays} from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import ro from "date-fns/locale/ro"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import {refreshDay} from "../../../store/actions/refreshDayR";
import TableColumnZi from "../tables/TableColumnZi";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {BeatLoader} from "react-spinners";

class SliderTableZi extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeWeek = this.changeWeek.bind(this);
        this.state ={
            startDate: new Date(),
            blocked: null,
            loading: false
        }
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    componentDidMount() {
        const datePickers = document.getElementsByClassName("react-datepicker__input-container");
        Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    };


    refresh=(day)=>{
        this.props.setrefresh(day)
    }


    handleChange = date => {
        this.setState({
            startDate: date
        });
    }

    changeWeek = (date, loading) => {
        this.setState({
            startDate: date,
            loading: loading
        });

        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1500);
    }

    render(){
        registerLocale("ro", ro);
        const settings = {
            infinite: false,
            speed: 300,
            slidesToShow: 6.5,
            arrows:true,
            swipe:false,
            slidesToScroll:3,
            focusOnSelect: false,
            nextArrow: <ArrowForwardIosIcon className='arr next' />,
            prevArrow: <ArrowBackIosNewIcon className='arr prev' />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll:3,
                        swipe:true,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll:2,
                        swipe:true,
                    }
                }
            ]
        };
        const club = this.props.current.clublocation_id;
        const sport = this.props.current.sport_id;
        var currentDate;

        if(this.state.startDate == null){
            currentDate = moment().format('YYYY-MM-DD');
        }else{
            currentDate = moment(this.state.startDate).format('YYYY-MM-DD');
        }
        const time = new Date()
        var donew = new Date(currentDate);
        const ExampleCustomInput = ({ value, onClick }) => (
            <p className="custom-input" onClick={onClick}>
                {value ? moment(value).format('DD MMMM') + ' - ' + new Date(value).toLocaleString('ro', {weekday:'long'}) : 'Schimba data'}
            </p>
        );

        return(
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="form-group bmd-form-group m-0 p-0 with-day">
                        <DatePicker
                            locale="ro"
                            id="weeklyDatePicker"
                            calendarClassName="datepicker"
                            showPopperArrow ={false}
                            todayButton="Selecteaza data de azi"
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            placeholderText="Selecteaza o data"
                            minDate={subDays(new Date(), 180)}
                            maxDate={addDays(new Date(), 40)}
                            customInput={<ExampleCustomInput />}
                            popperPlacement='bottom-end'
                            popperModifiers={{
                                flip: {
                                    behavior: ['bottom'] // don't allow it to flip to be above
                                },
                                preventOverflow: {
                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                },
                                hide: {
                                    enabled: false // turn off since needs preventOverflow to be enabled
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 offset-md-4  text-right next-prev">
                    {moment(donew) > moment().subtract(180, 'd') &&
                    <button className="btn btn-success"
                    disabled = { this.state.loading === 'prev' ? "disabled" : ''}
                    onClick={() => this.changeWeek(donew.setDate(donew.getDate() - 1), 'prev')} >
                    {this.state.loading === 'prev' ? <BeatLoader color='#ffffff' loading={true}  size={8} /> : 'Prev'}
                    </button>}
                    {moment(donew) < moment().add(39, 'd') &&
                    <button className="btn btn-success"
                    disabled = { this.state.loading === 'next' ? "disabled" : ''}
                    onClick={() => this.changeWeek(donew.setDate(donew.getDate() + 1), 'next')}>
                    {this.state.loading === 'next' ? <BeatLoader color='#ffffff' loading={true}  size={8} /> : 'Next'}
                    </button>}
                </div>
                <div className="col-12">
                    <div className="wrapper-table-data">
                        <Slider className="wrapper-slides" ref={c => (this.slider = c)} {...settings}>
                            <div className="colum col-time">
                                <div className="cell head-cell">Ora</div>
                                <HoursColumn  club={club} sport={sport}/>
                            </div>
                            {this.props.terenuri && this.props.terenuri.map(teren => {
                                if(teren.sport_id === this.props.current.sport_id){
                                    return(
                                        <div className="colum" key={donew}>
                                            <TableColumnZi  blocked={this.state.blocked} key={this.props.refresh ===  teren.name ? time : currentDate} teren={teren} current = {this.props.current} day_nr = {currentDate} day_name ={teren.name} ref_teren={teren.name}/>
                                        </div>
                                    )
                                }
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        showModal:state.showModal.showModal,
        refresh: state.refreshDay.refreshDay
    }
}


export default connect(mapStateToProps,{openModal,refreshDay}) (SliderTableZi)
